export default [

	//Languages
	{
		path: '/languages/list',
		name: 'apps-languages-list',
		component: () => import('@/views/datamanagement/languages/LanguagesList.vue'),
		meta: {
			breadcrumb: [{
				text: 'Languages List',
				to: '/languages/list'
			}, ],
			pageTitle: 'Languages List'
		},
	}, {
		path: '/languages/edit/:id',
		name: 'apps-languages-edit',
		component: () => import('@/views/datamanagement/languages/LanguagesEdit.vue'),
		meta: {
			breadcrumb: [{
					text: 'Languages List',
					to: '/languages/list'
				},
				{
					text: 'Edit Language',
					to: '/languages/edit',
					active: true
				},
			],
			pageTitle: 'Edit Language',
			navActiveLink: 'apps-languages-list',
		},
	},
	{
		path: '/languages/new',
		name: 'apps-languages-new',
		component: () => import('@/views/datamanagement/languages/LanguagesAdd.vue'),
		meta: {
			breadcrumb: [{
				text: 'Languages List',
				to: '/languages/list'
			},
				{
					text: 'Add Language',
					to: '/languages/new',
					active: true
				},
			],
			pageTitle: 'Add Language',
			navActiveLink: 'apps-languages-list',
		},
	},

	//sections
	{
		path: '/sections/list',
		name: 'apps-sections-list',
		component: () => import('@/views/datamanagement/sections/SectionsList.vue'),
		meta: {
			breadcrumb: [{
				text: 'Sections List',
				to: '/sections/list'
			}, ],
			pageTitle: 'Sections List',
			navActiveLink: 'apps-sections-list',
		},
	}, {
		path: '/sections/edit/:id',
		name: 'apps-sections-edit',
		component: () => import('@/views/datamanagement/sections/SectionsEdit.vue'),
		meta: {
			breadcrumb: [{
					text: 'Sections List',
					to: '/sections/list'
				},
				{
					text: 'Edit Section',
					to: '/sections/edit'
				},
			],
			pageTitle: 'Edit Section',
			navActiveLink: 'apps-sections-list',
		},
	},
	{
		path: '/sections/new',
		name: 'apps-sections-new',
		component: () => import('@/views/datamanagement/sections/SectionsAdd.vue'),
		meta: {
			breadcrumb: [{
				text: 'Sections List',
				to: '/sections/list'
			},
				{
					text: 'Add Section',
					to: '/sections/new'
				},
			],
			pageTitle: 'Add Section',
			navActiveLink: 'apps-sections-list',
		},
	},

	//targetgroups
	{
		path: '/targetgroups/list',
		name: 'apps-targetgroups-list',
		component: () => import('@/views/datamanagement/targetgroups/TargetGroupsList.vue'),
		meta: {
			breadcrumb: [{
				text: 'Target Group List',
				to: '/targetgroups/list'
			}, ],
			pageTitle: 'Target Group List',
			navActiveLink: 'apps-targetgroups-list',
		},
	}, {
		path: '/targetgroups/edit/:id',
		name: 'apps-targetgroups-edit',
		component: () => import('@/views/datamanagement/targetgroups/TargetGroupsEdit.vue'),
		meta: {
			breadcrumb: [{
					text: 'Target Group List',
					to: '/targetgroups/list'
				},
				{
					text: 'Edit Target Group',
					to: '/targetgroups/edit',
					active: true
				},
			],
			pageTitle: 'Edit Target Group',
			navActiveLink: 'apps-targetgroups-list',
		},
	},
	{
		path: '/targetgroups/new',
		name: 'apps-targetgroups-new',
		component: () => import('@/views/datamanagement/targetgroups/TargetGroupsAdd.vue'),
		meta: {
			breadcrumb: [{
				text: 'Target Group List',
				to: '/targetgroups/list'
			},
				{
					text: 'Add Target Group',
					to: '/targetgroups/new',
					active: true
				},
			],
			pageTitle: 'Add Target Group',
			navActiveLink: 'apps-targetgroups-list',
		},
	},

	//experttopics
	{
		path: '/experttopics/list',
		name: 'apps-experttopics-list',
		component: () => import('@/views/datamanagement/experttopics/ExpertTopicsList.vue'),
		meta: {
			breadcrumb: [{
				text: 'Expert Topic List',
				to: '/experttopics/list'
			}, ],
			pageTitle: 'Expert Topic List',
			navActiveLink: 'apps-experttopics-list',
		},
	}, {
		path: '/experttopics/edit/:id',
		name: 'apps-experttopics-edit',
		component: () => import('@/views/datamanagement/experttopics/ExpertTopicsEdit.vue'),
		meta: {
			breadcrumb: [{
					text: 'Expert Topic List',
					to: '/experttopics/list'
				},
				{
					text: 'Edit Expert Topic',
					to: '/experttopics/edit',
					active: true
				},
			],
			pageTitle: 'Edit Expert Topic',
			navActiveLink: 'apps-experttopics-list',
		},
	}, {
		path: '/experttopics/new',
		name: 'apps-experttopics-new',
		component: () => import('@/views/datamanagement/experttopics/ExpertTopicsAdd.vue'),
		meta: {
			breadcrumb: [{
				text: 'Expert Topic List',
				to: '/experttopics/list'
			},
				{
					text: 'Add Expert Topic',
					to: '/experttopics/new',
					active: true
				},
			],
			pageTitle: 'Add Expert Topic',
			navActiveLink: 'apps-experttopics-list',
		},
	},

	//eventtypes
	{
		path: '/eventtypes/list',
		name: 'apps-event-types-list',
		component: () => import('@/views/datamanagement/eventtypes/EventTypesList.vue'),
		meta: {
			breadcrumb: [{
				text: 'Event Types List',
				to: '/eventtypes/list'
			}, ],
			pageTitle: 'Event Types List',
			navActiveLink: 'apps-event-types-list',
		},
	}, {
		path: '/eventtypes/edit/:id',
		name: 'apps-event-types-edit',
		component: () => import('@/views/datamanagement/eventtypes/EventTypesEdit.vue'),
		meta: {
			breadcrumb: [{
					text: 'Event Types List',
					to: '/eventtypes/list'
				},
				{
					text: 'Edit Event Type',
					to: '/eventtypes/edit',
					active: true
				},
			],
			pageTitle: 'Edit Event Type',
			navActiveLink: 'apps-event-types-list',
		},
	},
	{
		path: '/eventtypes/new',
		name: 'apps-event-types-new',
		component: () => import('@/views/datamanagement/eventtypes/EventTypesAdd.vue'),
		meta: {
			breadcrumb: [{
				text: 'Event Types List',
				to: '/eventtypes/list'
			},
				{
					text: 'Add Event Type',
					to: '/eventtypes/new',
					active: true
				},
			],
			pageTitle: 'Add Event Type',
			navActiveLink: 'apps-event-types-list',
		},
	},


	{
		path: '/invoice',
		name: 'invoice-data',
		component: () => import('@/views/invioces/Invoice-managment.vue'),
		meta: {
			breadcrumb: [{
				text: 'PDF/Invoice-data',
				to: '/invoice'
			},

			],

			pageTitle: 'PDF/Invoice-data',
			navActiveLink: 'invoice-data',
		},
	},



]
