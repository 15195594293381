export default [
    //Experts
    {
        path: '/faq/categories/list',
        name: 'faq-categories-list',
        component: () => import('@/views/faq/categories/CategoriesList.vue'),
        meta: {
            breadcrumb: [{
                text: 'FAQ category list',
                url: '/faq/categories/list',
                active: true
            },],
            pageTitle: 'FAQ category list'
        },
    }, {
        path: '/faq/categories/:id/edit',
        name: 'faq-categories-edit',
        component: () => import('@/views/faq/categories/CategoriesEdit.vue'),
        meta: {
            breadcrumb: [{
                text: 'FAQ category list',
                to: '/faq/categories/list'
            },
            {
                text: 'FAQ category edit',
                active: true
            },
            ],
            pageTitle: 'Edit FAQ category',
            navActiveLink: 'faq-categories-list',
        },
    }, {
        path: '/faq/categories/new/',
        name: 'faq-categories-new',
        component: () => import('@/views/faq/categories/CategoriesAdd.vue'),
        meta: {
            breadcrumb: [{
                text: 'FAQ category list',
                to: '/faq/categories/list'
            },
            {
                text: 'New FAQ category',
                active: true
            },
            ],
            pageTitle: 'New FAQ category',
            navActiveLink: 'faq-categories-list',

        },
    },

    // Entries

    {
        path: '/faq/entries/list',
        name: 'faq-entries-list',
        component: () => import('@/views/faq/entries/EntriesList.vue'),
        meta: {
            breadcrumb: [{
                text: 'FAQ entry list',
                url: '/entries/list',
                active: true
            },],
            pageTitle: 'FAQ entry list'
        },
    }, {
        path: '/faq/entries/:id/edit',
        name: 'faq-entries-edit',
        component: () => import('@/views/faq/entries/EntriesEdit.vue'),
        meta: {
            breadcrumb: [{
                text: 'FAQ entry list',
                to: '/entries/list'
            },
            {
                text: 'FAQ entry edit',
                active: true
            },
            ],
            pageTitle: 'FAQ entry edit',
            navActiveLink: 'faq-entries-list',
        },
    }, {
        path: '/faq/entries/new/',
        name: 'faq-entries-new',
        component: () => import('@/views/faq/entries/EntriesAdd.vue'),
        meta: {
            breadcrumb: [{
                text: 'FAQ entry list',
                to: '/entries/list'
            },
            {
                text: 'New FAQ entry',
                active: true
            },
            ],
            pageTitle: 'New FAQ entry',
            navActiveLink: 'faq-entries-list',

        },
    }
]
