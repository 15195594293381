export default [
  //Experts
  {
    path: '/experts/list',
    name: 'apps-experts-list',
    component: () => import('@/views/experts/ExpertsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          url: '/users/list',
          active: true
        },
      ],
      pageTitle: 'Expert List'
    },
  },
  {
    path: '/experts/:expertid/edit',
    name: 'apps-experts-edit',
    component: () => import('@/views/experts/ExpertsEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Expert Edit',
          active: true
        },
      ],
      pageTitle: 'Edit Expert',
      navActiveLink: 'apps-experts-list',
    },
  },
  {
    path: '/experts/new/',
    name: 'apps-experts-new',
    component: () => import('@/views/experts/ExpertsAdd.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'New Expert',
          active: true
        },
      ],
      pageTitle: 'New Expert',
      navActiveLink: 'apps-experts-list',

    },
  },
  //experts Video Nuggets
  {
    path: '/experts/:expertid/videonuggets/list',
    name: 'apps-experts-videosnippets-list',
    component: () => import('@/views/experts/videosnippets/VideoSnippetsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit/',
        },
        {
          text: 'Video Nuggets List',
          to: '/experts/:expertid/videonuggest/list',
          active: true
        },
      ],
      pageTitle: 'Video Nuggets List',
      navActiveLink: 'apps-experts-list',
    },
  },
  {
    path: '/experts/:expertid/videonuggets/new',
    name: 'apps-experts-videosnippets-new',
    component: () => import('@/views/experts/videosnippets/VideoSnippetsAdd.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit/',
        },
        {
          text: 'Video Nuggets List',
          to: '/experts/:expertid/videonuggets/list'
        },
        {
          text: 'Add Video Nugget',
          to: '/experts/videonuggets/new',
          active: true
        },
      ],
      pageTitle: 'Add Video Nugget',
      navActiveLink: 'apps-experts-list',
    },
  },
  {
    path: '/experts/:expertid/videonuggets/:videonuggetid/edit',
    name: 'apps-experts-videosnippets-edit',
    component: () => import('@/views/experts/videosnippets/VideoSnippetsEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit/',
        },
        {
          text: 'Video Nuggets List',
          to: '/experts/:expertid/videonuggets/list'
        },
        {
          text: 'Edit Video Nugget',
          to: '/experts/:expertid/videonuggets/:videonuggetid/edit',
          active: true
        },
      ],
      pageTitle: 'Edit Video Nugget',
      navActiveLink: 'apps-experts-list',
    },
  },

  // Experts Events
  {
    path: '/experts/:expertid/events/list',
    name: 'apps-experts-events-list',
    component: () => import('@/views/experts/events/EventsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit',
        },
        {
          text: 'Events List',
          to: '/experts/:expertid/events/list',
          active: true
        },
      ],
      pageTitle: 'Events List',
      navActiveLink: 'apps-experts-list',
    },
  },
  {
    path: '/experts/:expertid/events/new',
    name: 'apps-experts-events-new',
    component: () => import('@/views/experts/events/EventsAdd.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit',
        },
        {
          text: 'Events List',
          to: '/experts/:expertid/events/list'
        },
        {
          text: 'Add Event',
          to: '/experts/events/new',
          active: true
        },
      ],
      pageTitle: 'Add Event',
      navActiveLink: 'apps-experts-list',
    },
  },
  {
    path: '/experts/:expertid/events/:id/edit',
    name: 'apps-experts-events-edit',
    component: () => import('@/views/experts/events/EventsEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit',
        },
        {
          text: 'Events List',
          to: '/experts/:expertid/events/list'
        },
        {
          text: 'Edit Event',
          to: '/experts/events/edit',
          active: true
        },
      ],
      pageTitle: 'Edit Event',
      navActiveLink: 'apps-experts-list',
    },
  },

  // Experts BookingTimes
  {
    path: '/experts/:expertid/bookingtimes/list',
    name: 'apps-experts-bookingtimes-list',
    component: () => import('@/views/experts/bookingtimes/BookingTimesList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit',
        },
        {
          text: 'Booking Times List',
          to: '/experts/:eventid/bookingtimes/list',
          active: true
        },
      ],
      pageTitle: 'Booking Times List',
      navActiveLink: 'apps-experts-list',
    },
  },
  {
    path: '/experts/:expertid/bookingtimes/new',
    name: 'apps-experts-bookingtimes-new',
    component: () => import('@/views/experts/bookingtimes/BookingTimesAdd.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit',
        },
        {
          text: 'Booking Times List',
          to: '/experts/:expertid/bookingtimes/list'
        },
        {
          text: 'Add Booking Time',
          to: '/experts/bookingtimes/new',
          active: true
        },
      ],
      pageTitle: 'Add Booking Time',
      navActiveLink: 'apps-experts-list',
    },
  },
  {
    path: '/experts/:expertid/bookingtimes/:bookingtimeid/edit',
    name: 'apps-experts-bookingtimes-edit',
    component: () => import('@/views/experts/bookingtimes/BookingTimesEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit',
        },
        {
          text: 'Booking Times List',
          to: '/experts/:expertid/bookingtimes/list'
        },
        {
          text: 'Edit Booking Time',
          to: '/experts/bookingtimes/edit',
          active: true
        },
      ],
      pageTitle: 'Edit Booking Time',
      navActiveLink: 'apps-experts-list',
    },
  },
  {
    path: '/expert/:expertid/bookings',
    name: 'apps-expert-booking-list',
    component: () => import('@/views/experts/bookings/ExpertBookingsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit',
        },
        {
          text: 'Bookings',
          active: true
        },
      ],
      pageTitle: 'Bookings List'
    },
  },
  {
    path: '/experts/:expertid/bookings/:bookingid',
    name: 'apps-expert-booking-details',
    component: () => import('@/views/experts/bookings/BookingDetails.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: 'experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit',
        },
        {
          text: 'Bookings',
          to: '/expert/:expertid/bookings',
        },
        {
          text: 'Bookings details',
          active: true
        },
      ],
      pageTitle: 'Bookings details'
    },
  },
  {
    path: '/experts/:expertid/addcredits',
    name: 'apps-experts-credits',
    component: () => import('@/views/experts/credits/AddCredits.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Expert List',
          to: '/experts/list'
        },
        {
          text: 'Edit Expert',
          to: '/experts/:expertid/edit',
        },
        {
          text: 'Add credits',
          active: true
        },
      ],
      pageTitle: 'Add credits'
    },
  },

]
