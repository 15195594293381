export default [

  //Admin-Users
  {
    path: '/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/users/UsersList.vue'),
    meta: {
      breadcrumb: [{
        text: 'Admin List',
        url: '/users/list',
        active: true
      }, ],
      pageTitle: 'Admin List',
      navActiveLink: 'apps-users-list',
    },
  },

  {
    path: '/users/:userid/edit',
    name: 'apps-users-edit',
    component: () => import('@/views/users/UsersEdit.vue'),
    meta: {
      breadcrumb: [{
          text: 'Admin List',
          to: '/users/list'
        },
        {
          text: 'Edit Admin',
          to: '/users/edit',
          active: true
        },
      ],
      pageTitle: 'Edit Admin',
      navActiveLink: 'apps-users-list',
    },
  },

  {
    path: '/users/new',
    name: 'apps-users-new',
    component: () => import('@/views/users/UsersAdd.vue'),
    meta: {
      breadcrumb: [{
        text: 'Admin List',
        to: '/users/list'
      },
        {
          text: 'Add Admin',
          active: true
        },
      ],
      pageTitle: 'Add Admin',
      navActiveLink: 'apps-users-list',
    },
  },

]
