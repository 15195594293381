export default [
	//emailtemplates
	{
		path: '/emailtemplates/list',
		name: 'apps-emailtemplates-list',
		component: () => import('@/views/emailtemplates/EmailTemplatesList.vue'),
		meta: {
			breadcrumb: [{
				text: 'Email Template List',
				to: '/emailtemplates/list'
			}, ],
			pageTitle: 'Email Template List'
		},
	}, {
		path: '/emailtemplates/edit/:id',
		name: 'apps-emailtemplates-edit',
		component: () => import('@/views/emailtemplates/EmailTemplatesEdit.vue'),
		meta: {
			breadcrumb: [{
					text: 'Email Template List',
					to: '/emailtemplates/list'
				},
				{
					text: 'Edit Email Template',
					to: '/emailtemplates/list',
					active: true
				},
			],
			pageTitle: 'Edit Email Template',
			navActiveLink: 'apps-emailtemplates-list',
		},
	}, {
		path: '/emailtemplates/new/',
		name: 'apps-emailtemplates-new',
		component: () => import('@/views/emailtemplates/EmailTemplatesAdd.vue'),
		meta: {
			breadcrumb: [{
					text: 'Email Template List',
					to: '/emailtemplates/list'
				},
				{
					text: 'Add New Email Template',
					to: '/emailtemplates/new',
					active: true
				},
			],
			pageTitle: 'Add New Email Template',
			navActiveLink: 'apps-emailtemplates-list',
		},
	},

]
