<template>
  <div v-if="showButton" class="btn-help show">
    <!-- We have wrapper because ripple effect give position relative to this absolute positioned btn -->
    <!-- Hence due to that our btn get lost -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="danger"
      class="btn-icon"
      @click="showHelpSidebar"
      >Help
      <feather-icon icon="HelpCircleIcon" size="16" />
    </b-button>
    <help-sidebar v-model="isHelpSidebarActive" :slug="slug" />
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import HelpSidebar from "@/views/components/help-area/HelpSidebar.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    HelpSidebar,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    allowHelp: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isHelpSidebarActive: false,
      showButton: false,
      slugs: [],
      slug: "",
    };
  },
  watch: {
    allowHelp: function () {
      this.getItems();
    },
  },
  methods: {
    showHelpSidebar() {
      this.isHelpSidebarActive = true;
    },
    getItems() {
      this.$http.get(`/helparea/admin`).then((res) => {
        this.slugs = res.data.items.map((item) => item.slug);
        if (this.slugs.includes(this.allowHelp)) {
          this.showButton = true;
          this.slug = this.allowHelp;
        } else {
          this.showButton = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-help {
  position: fixed;
  bottom: 5%;
  right: 80px;
  z-index: 99;
  opacity: 0;
  transition: all 0.5s ease;
  &.show {
    opacity: 1;
  }
}
</style>