export default [
    {
        path: '/media-manager',
        name: 'apps-media-manager',
        component: () => import('@/views/mediamanager/MediaManager.vue'),
        meta: {
            breadcrumb: [{
                text: 'Media Manager',
                url: '/media-manager',
                active: true
            },],
            pageTitle: 'Media Manager',
            navActiveLink: 'apps-media-manager',
        },
    },
]