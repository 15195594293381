export default [
    {
        path: '/helps/list',
        name: 'apps-helps-list',
        component: () => import('@/views/helps/HelpsList.vue'),
        meta: {
            breadcrumb: [{
                text: 'Help Area List',
                url: '/helps/list',
                active: true
            },],
            pageTitle: 'Help Area List',
            navActiveLink: 'apps-helps-list',
        },
    },
    {
        path: '/helps/:helpsId/edit',
        name: 'apps-helps-edit',
        component: () => import('@/views/helps/HelpsEdit.vue'),
        meta: {
            breadcrumb: [{
                text: 'Helps List',
                to: '/helps/list'
            },
            {
                text: 'Edit Help Area',
                to: '/helps/edit',
                active: true
            },
            ],
            pageTitle: 'Edit Help Area',
            navActiveLink: 'apps-helps-list',
        },
    },

    {
        path: '/helps/new',
        name: 'apps-helps-new',
        component: () => import('@/views/helps/HelpsAdd.vue'),
        meta: {
            breadcrumb: [{
                text: 'Help List',
                to: '/helps/list'
            },
            {
                text: 'Add Help Area',
                active: true
            },
            ],
            pageTitle: 'Add Help Area',
            navActiveLink: 'apps-helps-list',
        },
    },

]