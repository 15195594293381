export default [

    //Experts
    {
        path: '/affiliates/users',
        name: 'apps-affiliates-list',
        component: () => import('@/views/affiliates/AffiliateList.vue'),
        meta: {
            breadcrumb: [{
                text: 'Affiliate users',
                url: '/affiliates/users',
                active: true
            },],
            pageTitle: 'Affiliate users'
        },
    },
    {
        path: '/affiliates/:affiliateid/edit',
        name: 'apps-affiliates-edit',
        component: () => import('@/views/affiliates/AffiliateEdit.vue'),
        meta: {
            breadcrumb: [{
                text: 'affiliates List',
                to: '/affiliates/users'
            },
            {
                text: 'Affiliate user edit',
                active: true
            },
            ],
            pageTitle: 'Edit affiliate user',
            navActiveLink: 'apps-affiliates-list',
        },
    },
    {
        path: '/affiliates/new/',
        name: 'apps-affiliates-new',
        component: () => import('@/views/affiliates/AffiliateAdd.vue'),
        meta: {
            breadcrumb: [{
                text: 'Affiliate users',
                to: '/affiliates/list'
            },
            {
                text: 'New affiliate user',
                active: true
            },
            ],
            pageTitle: 'New affiliate user',
            navActiveLink: 'apps-affiliates-list',

        },
    },
]
