import Vue from 'vue'
import {
  ToastPlugin,
  ModalPlugin
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/helper'
import '@/libs/helper/breadcrumbs'
import '@/libs/helper/table'
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
import FileManager from 'laravel-file-manager'


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)


// Composition API
Vue.use(VueCompositionAPI)
Vue.use(wysiwyg, {});
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(FileManager, {store});


// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
