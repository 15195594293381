import Vue from 'vue'

Vue.mixin({
  data() {
    return {
      breadcrumbData: null
    }
  },
  methods: {
    helperGetItem: async function (itemUrl, onSuccess = function() {}, onError = function() {}) {
      this.loaded = false
      this.$http.get(itemUrl)
        .then(response => {
          this.loaded = true
          this.currentItem = response.data

          if (onSuccess) onSuccess(response.data, this)
        })
        .catch(error => {
          console.debug(error)


          if (onError) onError(error.response.data, this)
          this.loaded = false
        })
    },
    helperRefreshItem: async function (method, card) {

    },
    helperGetItems: async function (url, onSuccess = function() {}, onError = function() {}) {
      this.loaded = false

      this.$http.get(url)
        .then(response => {
          this.items = response.data.items
          this.totalRows = response.data.items.length
          this.loaded = true

          if (onSuccess) onSuccess(response, this)
        })
        .catch(error => {
          console.debug(error)
          this.loaded = true
          if (error.response.status === 404) {
          }

          if (onError) onError(error, this)
        })
    },
    /**
     * @param url
     * @param data
     * @param refTable
     * @param route
     * @param onSuccess
     * @param onError
     * @returns {Promise<methods>}
     */
    helperAddItem: async function (url, data, refForm, route, onSuccess = function() {}, onError = function() {}) {
      this.loaded = false

      await refForm.validate()
        .then((success) => {
          this.validation = {}
          this.validate = false
          this.loaded = false

          if (success) {
            this.$http.post(url, data)
              .then(response => {
                this.loaded = true

                let toastData = {
                  title: this.$i18n.t('Success'),
                  variant: 'success',
                  toaster: 'b-toaster-bottom-right'
                }

                if (route) {
                  this.$router.push(
                    route,
                    () => setTimeout(() => {
                      this.$bvToast.toast(this.$i18n.t('Item saved'), toastData)
                    })
                  )
                } else {
                  this.$bvToast.toast(this.$i18n.t('Item saved'), toastData)
                }
                if (onSuccess) onSuccess(response, this)
              })
              .catch(error => {
                this.loaded = true

                if (error.response.status == 422) {
                  this.validation = error.response.data.errors
                  this.validate = true
                } else {
                  this.$bvToast.toast(error.response.data.message, {
                    title: this.$i18n.t('Error'),
                    variant: 'danger',
                    toaster: 'b-toaster-bottom-right'
                  })
                }

                if (onError) onError(error, this)
              })
          } else {
            console.debug(1)
            this.loaded = true
          }
        })

      return this
    },
    helperUpdateItem: async function (updateUrl, data, onSuccess = function() {}, onError = function() {}) {
      this.loaded = false

      try {
        let response = await this.$http.put(updateUrl, data)
        if (onSuccess) onSuccess(response.data, this)

        this.$bvToast.toast(this.$i18n.t('Item saved'), {
          title: this.$i18n.t('Success'),
          variant: 'success',
          icon: 'BellIcon',
          toaster: 'b-toaster-bottom-right'
        })
        this.loaded = true
      } catch (error) {
        if (onError) onError(error, this)

        if (error?.response?.status == 422) {
          this.validation = error.response.data.errors
          this.validate = true
        }
        this.$bvToast.toast(this.$i18n.t('Item not saved'), {
          title: this.$i18n.t('Error'),
          variant: 'danger',
          toaster: 'b-toaster-bottom-right'
        })

        this.$refs.refFormObserver.setErrors(error.response.data.errors)


        this.loaded = true
      }
    },

    helperDeleteItem: async function (deleteUrl, refTable, routeName, params, onSuccess = function() {}, onError = function() {}) {
      if (deleteUrl === 'undefined') {
        return
      }

      this.loaded = false

      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t('You won\'t be able to revert this!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it!'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.delete(deleteUrl)
              .then(response => {
                let toastData = {
                  title: this.$i18n.t('Success'),
                  variant: 'success',
                  toaster: 'b-toaster-bottom-right'
                }

                if (routeName) {
                  this.$router.push({
                    name: routeName,
                    params: params
                  },
                    () => setTimeout(() => {
                      this.$bvToast.toast(this.$i18n.t('Item deleted'), toastData)
                    }))

                  return;
                }

                // if (refTable) {
                //     refTable.refresh();
                // }
                this.items = []
                this.loaded = false
                this.getItems()

                this.$bvToast.toast(this.$i18n.t('Item deleted'), toastData)
              })
              .catch(error => {
                console.debug(error)
                // this.loaded = false
                this.$bvToast.toast(this.$i18n.t('Item not deleted'), {
                  title: this.$i18n.t('Error'),
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-right'
                })
              })
          } else {
            this.loaded = true
          }
        })
    },
    hToast: async function (type, title, message) {
      this.$bvToast.toast(this.$i18n.t(message), {
        title: this.$i18n.t(title),
        variant: type,
        toaster: 'b-toaster-bottom-right'
      })

      return this
    },
    hRedirect: async function (routeName, params, callback) {
      this.$router.push(
        {
          name: routeName,
          params: params
        },
        () => setTimeout(() => {
        })
      )

      return this
    },
    getTableOptions() {
      return {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
      }
    }
  },
})
