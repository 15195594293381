export default [

  //Clients
  {
    path: '/clients/list',
    name: 'apps-clients-list',
    component: () => import('@/views/clients/ClientsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
      ],
      pageTitle: 'Clients List'
    },
  },
  {
    path: '/clients/new',
    name: 'apps-clients-new',
    component: () => import('@/views/clients/ClientsAdd.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Add Client',
          to: '/clients/new',
          active: true
        },
      ],
      pageTitle: 'Add Client',
      navActiveLink: 'apps-clients-list',
    },
  },
  {
    path: '/clients/:clientid/edit',
    name: 'apps-clients-edit',
    component: () => import('@/views/clients/ClientsEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Clients Edit',
          to: '/clients/edit',
          active: true
        },
      ],
      pageTitle: 'Clients Edit',
      navActiveLink: 'apps-clients-list',
    },
  },

  // Client Users
  {
    path: '/clients/:clientid/users/list',
    name: 'apps-clients-users-list',
    component: () => import('@/views/clients/users/UsersList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Clients Edit',
          to: '/clients/:clientid/edit',
        },
        {
          text: 'User List',
          to: '/clients/:clientid/users/list',
          active: true
        },
      ],
      pageTitle: 'User List',
      navActiveLink: 'apps-clients-list',
    },
  },
  {
    path: '/clients/:clientid/users/new',
    name: 'apps-clients-user-new',
    component: () => import('@/views/clients/users/UsersAdd.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Clients Edit',
          to: '/clients/:clientid/edit',
        },
        {
          text: 'Users',
          to: '/clients/:clientid/users/list'
        },
        {
          text: 'Add User',
          to: '/clients/users/new',
          active: true
        },
      ],
      pageTitle: 'Add User',
      navActiveLink: 'apps-clients-list',
    },
  },
  {
    path: '/clients/:clientid/users/:userid/edit',
    name: 'apps-clients-users-edit',
    component: () => import('@/views/clients/users/UsersEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Clients Edit',
          to: '/clients/:clientid/edit',
        },
        {
          text: 'Users',
          to: '/clients/:clientid/users/list'
        },
        {
          text: 'Edit User',
          to: '/clients/users/edit',
          active: true
        },
      ],
      pageTitle: 'Edit User',
      navActiveLink: 'apps-clients-list',
    },
  },
  {
    path: '/client/:clientid/bookings',
    name: 'apps-client-booking-list',
    component: () => import('@/views/clients/bookings/ClientBookingsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Clients Edit',
          to: '/clients/:clientid/edit',
        },
        {
          text: 'Bookings',
          active: true
        },
      ],
      pageTitle: 'Bookings List',
      navActiveLink: 'apps-clients-list',
    },
  },
  {

    path: '/clients/:clientid/bookings/:bookingid',
    name: 'apps-client-booking-details',
    component: () => import('@/views/clients/bookings/BookingDetails.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Bookings details',
          active: true
        },
      ],
      pageTitle: 'Bookings details',
      navActiveLink: 'apps-clients-list',
    },
  },
  {
    path: '/clients/:clientid/user/:userid/bookings',
    name: 'apps-client-users-booking-list',
    component: () => import('@/views/clients/users/bookings/ClientUserBookingsList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Clients Edit',
          to: '/clients/:clientid/edit',
        },
        {
          text: 'Users',
          to: '/clients/:clientid/users/list'
        },
        {
          text: 'Edit User',
          to: '/clients/:clientid/users/:userid/edit',
        },
        {
          text: 'Bookings',
          active: true
        },
      ],
      pageTitle: 'Bookings List',
      navActiveLink: 'apps-clients-list',
    },
  },
  {
    path: '/clients/:clientid/user/:userid/bookings/:bookingid',
    name: 'apps-client-users-booking-details',
    component: () => import('@/views/clients/users/bookings/BookingDetails.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Clients Edit',
          to: '/clients/:clientid/edit',
        },
        {
          text: 'Users',
          to: '/clients/:clientid/users/list'
        },
        {
          text: 'Edit User',
          to: '/clients/:clientid/users/:userid/edit',
        },
        {
          text: 'Bookings',
          to: '/clients/:clientid/user/:userid/bookings'
        },
        {
          text: 'Bookings details',
          active: true
        },
      ],
      pageTitle: 'Bookings details',
      navActiveLink: 'apps-clients-list',
    },
  },
  {
    path: '/client/:clientid/bookings/user/:userid/addcredits',
    name: 'apps-client-users-add-credits',
    component: () => import('@/views/clients/users/credits/AddCredits.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Clients List',
          to: '/clients/list'
        },
        {
          text: 'Clients Edit',
          to: '/clients/:clientid/edit',
        },
        {
          text: 'Users',
          to: '/clients/:clientid/users/list'
        },
        {
          text: 'Edit User',
          to: '/clients/:clientid/users/:userid/edit',
        },
        {
          text: 'Add credits',
          active: true
        },
      ],
      pageTitle: 'Add credits',
      navActiveLink: 'apps-clients-list',
    },
  },
]
